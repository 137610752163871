.template-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    width: 100%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    justify-content: center;
    align-items: start;
    font-size: 12px;
    position: relative;
}

.template-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-bottom: 20px;
    color: #6c757d;
    font-size: 16px;
}

.template-form input {
    margin-top: 2px;
    font-size: 16px;
    height: 30px;
    border: 1px solid rgb(204, 204, 204);
    padding: 0px 0px 0px 5px;
    position: relative;
    z-index: 2;
}

.template-form select {
    padding: 0px;
    margin-top: 2px;
    font-size: 16px;
    height: 30px;
    width: auto;
    background-color: #ffffff;
    color: #6c757d;
    border: 1px solid rgb(204, 204, 204);
}

.template-form textarea {
    padding: 0px;
    margin-top: 2px;
    font-size: 16px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
}

.template-form input[type="file"] {
    padding: 0px;
    margin-top: 2px;
    font-size: 16px;
    height: 30px;
    border: none;
}

.template-form > *:last-child{
    align-items: center
}

.template-form button {
    grid-column: span 2;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
    width: 60%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.3);
    align-items: center
}

.template-form button:hover {
    background-color: #0056b3;
    box-shadow: 0px 6px 12px rgba(0, 123, 255, 0.4);
}

.template-form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    box-shadow: none;
}

.constructionSite-data {
    background-color: white;
}

.template-form .error {
    color: red;
    font-size: 8px;
    margin-top: 2px;
}

.search-results {
    list-style-type: none;
    padding: 0;
    margin-top: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - 10px);
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.search-results-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
}