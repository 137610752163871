.edit-item-modal {
    position: fixed;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
}

.modal-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content label {
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    gap: 20px   ;
    justify-content: space-between;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.modal-buttons button:hover {
    background-color: #0056b3;
}
