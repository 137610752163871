/* General Navbar Styles */
.navbar {
  background: var(--white-color);
  padding: 0.5rem 1rem;
}

.navbar-brand {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}

.logo-image {
  width: 65px;
  margin-right: 15px;
}

.logo-text {
  color: var(--primary-color);
  font-size: var(--h4-font-size);
  line-height: normal;
}

.logo-slogan {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: var(--font-weight-bold);
}

.logo-icon {
  color: #007bff; /* Customize your color */
}

.navbar-expand-lg .navbar-nav .nav-link {
  margin-right: 0;
  margin-left: 0;
  padding: 20px;
  transition: color 0.3s, background-color 0.3s;
  color: var(--p-bg-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-nav {
  background-color: aquamarine;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--custom-btn-bg-color);
  background-color: var(--hover-bg-color);
}

/* Burger Menu */
.navbar-toggler {
  display: none; /* Hide the burger menu by default */
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 30px;
  height: 35px;
}

/* Show the burger menu on smaller screens */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
    z-index: 9999 !important;
  }

  .navbar-brand {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-collapse {
    text-align: center;
    width: 100%;
  }

  .create_btn {
    margin-bottom: 0.5rem;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px;
  }
}

/* Dropdown Menu */
.dropdown-menu {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border: 0;
  padding: 0;
  margin-top: 20px;
}

.dropdown-item {
  color: var(--p-bg-color);
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--border-color);
  padding-top: 10px;
  padding-bottom: 10px;
  transition: color 0.3s, background-color 0.3s;
}

.dropdown-item.active, 
.dropdown-item:active,
.dropdown-item:focus, 
.dropdown-item:hover {
  background: var(--hover-bg-color);
  color: var(--custom-btn-bg-color);
}

/* Custom Buttons */
.create_btn {
  background: linear-gradient(135deg, #007bff, #0056b3); 
  color: white;
  padding: 4px 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  letter-spacing: 1px;
  border: none;
}

.create_btn:hover {
  background: linear-gradient(135deg, #0056b3, #003d79); 
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.custom-btn {
  background: var(--custom-btn-bg-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  color: var(--dark-color);
  font-size: var(--btn-font-size);
  padding: 15px 20px;
  width: 6rem;
}

.custom-btn:hover,
.navbar-expand-lg .navbar-nav .nav-link.custom-btn:hover {
  background: var(--secondary-color);
  color: var(--hover-bg-color);
}

/* Responsive Styles */
@media screen and (min-width: 1025px) {
  .job-featured-section .job-thumb {
    max-width: 1120px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 991px) {
  .google-map {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding,
  .cta-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .about-page .about-section {
    padding-top: 50px;
  }

  .custom-btn {
    padding: 12px 20px;
  }

  .navbar-collapse {
    padding-bottom: 20px;
  }

  .navbar-expand-lg .navbar-nav .nav-link.custom-btn {
    margin-top: 10px;
  }

  .page-link {
    font-size: 12px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .custom-text-block {
    padding: 50px 30px;
  }

  .hero-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .badge {
    margin-top: 5px;
  }

  .counter-number, 
  .counter-number-text {
    font-size: var(--h2-font-size);
  }

  .custom-border-radius-start {
    border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  }

  .custom-border-radius-end {
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
  }

  .job-body p {
    font-size: var(--btn-font-size);
  }

  .job-location {
    min-width: 140px;
  }

  .job-price {
    min-width: inherit;
    margin-top: 5px;
    margin-left: 0;
  }

  .hero-form button[type="submit"] {
    margin-bottom: 0;
  }

  .site-footer {
    padding-top: 50px;
  }

  .site-footer-bottom {
    margin-top: 50px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  .badge {
    margin: 10px 10px 5px 0;
  }

  .page-link {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .counter-thumb {
    margin: 10px;
  }

  .counter-number, 
  .counter-number-text {
    font-size: var(--h3-font-size);
  }

  .job-date {
    margin-top: 5px;
  }

  .job-detail-share {
    margin: 20px auto 0 auto;
  }
}


.navbar-collapse {
  z-index: 99999999 !important; 
  position: absolute; 
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  background-color: aliceblue; 
}


.navbar-nav {
  display: flex;
  flex-direction: column; 
  align-items: center;
  width: 100%;
}


.navbar-collapse.show {
  z-index: 1050; 
  position: absolute; 
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--white-color); 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}


.navbar-toggler {
  z-index: 1100; 
  position: relative;
}


.navbar-collapse.collapsing,
.navbar-collapse.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .navbar-collapse {
    z-index: 1050;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--white-color);
  }

  .navbar-collapse.show {
    z-index: 1050;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}