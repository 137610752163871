

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}



.dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.dropdown {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
}

.dropdown label {
    font-weight: bold;
    font-size: 18px;
    margin: 5%;
    color: #333;
}

.dropdown select {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease;
    width: fit-content;
}

.dropdown select:focus {
    border-color: #007bff;
    outline: none;
}

.template-container {
    background-color: #f0f0f0; 
    border-radius: 12px; 
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15), 
                0px 2px 4px rgba(0, 0, 0, 0.1);  
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    transition: box-shadow 0.3s ease;
}

.template-container.empty {
    color: #888;
    font-style: italic;
    font-size: 18px;
}
