
body {
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .sideMenuContainer {
    height: 90%; 
    display: flex;
    max-width: 100%;
    background-color: #cccccc;
  }
  
  .navPills .navLink {
    font-size: 20px !important; 
    font-weight: 500;
    color: #ffff;
    margin-bottom: 4px; 
    padding: 16px; 
  }

  .icon {
    margin-right: 5px; 
  }
  
  .navPills .navLink.active {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  .navPills .navLink:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  .tabContent {
    padding: 0px; 
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0px 0px;
    background-color: #fff;
    flex-grow: 1; 
    height: 100%;
    width: 100%; 
    overflow-y: auto; 
  }

  .flex_container {
    display: flex;
    flex-direction: column;
    height: 100vh; 
    max-width: 100%;
}


  