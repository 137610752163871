.flex_container {
    display: flex;
    min-height: 100vh;
  }
  
  .sideMenuContainer {
    width: 250px;
    background-color: #f0f0f0; 
    padding: 20px;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .navLink {
    display: block;
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 8px;
    color: #333 !important; 
    background-color: transparent; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .navLink:hover,
  .navLink.active {
    background-color: #ffffff; 
    color: #000000;
    width: 100%;
    text-align: center !important;
  }

.createAccountBtn {
  background-color: transparent !important; 
  color: #333 !important; 
  font-weight: bold !important; 
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px 15px !important; 
  border-radius: 5px !important; 
  transition: background-color 0.3s, color 0.3s !important; 
  
}

.createAccountBtn:hover
 {
  background-color: var(--primary-color) !important;
  color: var(--bs-nav-pills-link-active-color) !important; 
}
.createAccountBtn.active{
  background-color: var(--bs-nav-pills-link-active-bg) !important;
  color: var(--bs-nav-pills-link-active-color) !important; 
}

.createAccountBtn .icon {
  margin-right: 5px; 
}
  .tabContent {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px; 
  }
  
  .tab-pane {
    display: none;
  }
  
  .tab-pane.show {
    display: block;
  }
  
  /* successful message*/

  .successContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 0.25rem;
  }

  .successMessage {
      flex: 1;
      margin-right: 10px;
  }

  .closeButton {
      flex-shrink: 0;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      align-items: center;
      margin: 5px;
  }