.spinner_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .text_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }