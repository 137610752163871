/* ProfileCard.module.css */
.profile_card {
    background-color: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 20px;
    margin: 0 160px;
    height: 100vh;
  }

.edit_passwords {
  display: flex;
  flex-direction:column;
  align-content: center;
  justify-content: space-evenly;
  align-items: baseline;
}

@media screen and (max-width: 480px) {
  .profile_card {
    margin: 0;    
  }

}
  