.item-list {
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.item-list table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #ffffff; 
}

.item-list th, .item-list td {
    border: 1px solid #cccccc; 
    text-align: left;
    color: #333333; 
    padding: 10px;
    font-size: 16px;
}

.item-list th {
    background-color: #d9d9d9; 
    color: #333333; 
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.item-list tbody tr:nth-child(even) {
    background-color: #f2f2f2; 
}

.item-list-button {
    height: 20px;
    background-color: #4a4a4a; 
    color: #ffffff; 
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.item-list-button:hover {
    background-color: #333333; 
}

.item-list input[type="checkbox"] {
    transform: scale(0.8);
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.fas {
    color: #007bff; 
    cursor: pointer;
}

.order-info {
    background-color: #f5f5f5; 
    border: 1px solid #cccccc; 
    border-radius: 8px;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-bottom: 20px; 
}

.order-info p {
    margin: 10px 0;
    font-size: 16px;
    color: #333333; 
}

.order-info p a {
    color: #007bff; 
    text-decoration: none;
}

.order-info p a:hover {
    text-decoration: underline;
}

.order-info select {
    margin-left: 10px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff; 
    width: 100%;
    max-width: 200px;
}

.order-info select:focus {
    outline: none;
    border-color: #007bff; 
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.note-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
    border-radius: 8px;
}

.note-content {
    position: relative;
    text-align: center;
    width: 100%;
}

.close-button {
    position: absolute;
    bottom: 200px;
    left: 280px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.close-button:hover {
    color: red;
}

.note-button {
    margin: 0;
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    text-align: center;
    background-color: #4a4a4a; 
    color: #ffffff; 
    border-radius: 4px;
    cursor: pointer;
}

.note-button:hover {
    background-color: #333333; 
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .item-list table, .item-list th, .item-list td {
        font-size: 12px; 
        padding: 8px; 
    }

    .item-list th, .item-list td {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px; 
    }

    .order-info select {
        width: 100%;
    }
}

