.registerForm {
    width: -webkit-fill-available;
    position: relative;
    /* height: 100vh; */
    height: fit-content;
    margin: 20px 15px; /* Center the form horizontally */
    padding: 20px; /* Padding inside the form */
    background-color: #f9f9f9; /* Light background color */
    border: 1px solid #ccc; /* Border around the form */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }

  .formGroup {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .formField {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    width: calc(100% - 20px);
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  
  .errorInput {
    border-color: red;
  }
  
  .errorText {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
    margin-top: 10px;
  }
  
  button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .successContainer {
    margin-top: 20px;
}

.role_option {
  color: #000; 
  background-color: #f1f1f1; 
}

select.form-select option:checked.role_option,
select.form-select option:checked.role_option {
  color: #fff; 
  background-color: #4caf50; 
}
  