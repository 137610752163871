/* EditUserModal.css */
.modal_container {
    border-color: #ffff;
}
.form_label {
    color: #ffff !important;
}

.button_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

button[type="submit"].btn_success_modal {
    background-color: #008000;
    border-radius: 8px !important;
    margin-top: 40px;
}

.btn_close{
  margin-top: 40px !important
}

.form_container {
    background-color: rgb(255 255 255 / 44%) !important;
    height:fit-content;
}

/* Select field in the EditUserModal*/
.form_control__choice {
  background-color: #ffffff; 
  color: #000000; 
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px; 
}

.form_control__choice:focus {
  border-color: #80bdff; 
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); 
}

.form_control__choice option {
  background-color: #ffffff; 
  color: #000000; 
}

.form_control__choice option:hover {
  background-color: #f1f1f1; 
}

.form_control__choice option:checked {
  background-color: #e9ecef; 
  color: #495057; 
}
/* --------- */

/* .edit-user-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-user-modal .modal-header {
  border-bottom: 1px solid #e9ecef;
  padding: 15px;
}

.edit-user-modal .modal-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.edit-user-modal .modal-body {
  padding: 20px;
}

.edit-user-modal .modal-footer {
  border-top: 1px solid #e9ecef;
  padding: 15px;
}

.edit-user-modal .form-label {
  font-weight: bold;
}

.edit-user-modal .form-control {
  border-radius: 5px;
  border-color: #ced4da;
}

.edit-user-modal .form-control.is-invalid {
  border-color: #dc3545;
}

.edit-user-modal .invalid-feedback {
  display: block;
} */
