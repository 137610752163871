/* .dropdown-container {
    position: absolute;
    top: 19.5%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    background-color: #ff9900;

}

.dropdown {
    margin: 0;
    padding: 10px;
    width: 200px;
    font-size: 16px;

}

label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #6c757d;
}

.dropdown-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dropdown-container-date {
    background-color: #ffffff;
} */

.dropdown-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 30px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

#category, #constructionSite {
    width: 100%;
}

.dropdown {
    margin: 0;
    padding: 15px;
    width: 220px;
    font-size: 18px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
    font-size: 18px;
    margin-bottom: 8px;
    color: #333333;
}

.dropdown-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 240px;
}

.dropdown-container-date {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #cccccc;
    font-size: 16px;
}

textarea {
    font-size: 18px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    width: 100%;
    height: 100px;
    resize: vertical;
}

button[type="submit"] {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 8px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}


@media (max-width: 768px) {
    .dropdown-container {
        flex-direction: column;
        gap: 20px;
        padding: 15px;
    }

    .dropdown-container > div {
        width: 100%;
        max-width: 100%;
    }

    .dropdown {
        width: 100%;
        font-size: 16px;
    }

    label {
        font-size: 16px;
    }

    textarea {
        font-size: 16px;
        height: 80px;
    }

    button[type="submit"] {
        width: 100%;
        padding: 12px 0;
        font-size: 16px;
    }
}

.dropdown-container-required-field {
    font-size: 10px;
}