.forgotten-password {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f7f7f7; */
    background-color: #858585;

}

/* .forgotten-password-container {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 50%;
} */

.forgotten-password-container {
    background-color: rgba(255,255,255,0.13);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
    width: 50%;
    text-align: center;
}

.forgotten-password-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    /* color: #333; */
    color: lightgray;
}

.forgotten-password-container label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    /* color: #666; */
    color: lightgray;
}

.forgotten-password-container input {
    width: 100%; /* Инпутът заема цялата ширина на контейнера */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* За да се включат падинг и бордюр в ширината */
    margin-bottom: 20px; /* Разстояние под инпута */
}

.forgotten-password-container input:focus {
    border-color: #007bff; /* Промяна на цвета на бордюра при фокус */
    outline: none; /* Премахване на стандартната сянка при фокус */
}

.forgotten-password-container button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff; /* Син цвят на фона */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Плавен преход при промяна на фона */
}

.forgotten-password-container button:hover {
    background-color: #0056b3; /* По-тъмен син при задържане на бутона */
}

.forgotten-password-error {
    color: red;
}

.background{
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}

.shape:first-child{
    background: linear-gradient(
        #1845ad,
        #23a2f6
    );
    left: -350px;
    /* top: -80px; */
}
.shape:last-child{
    background: linear-gradient(
        to right,
        #ff512f,
        #f09819
    );
    right: -350px;
    bottom: 30px;
}

/* adjustment smaller screens */

@media (max-width: 768px) {
    .background {
        width: 350px;
        height: 420px;
    }
    
    .shape:first-child {
        left: -200px;
        top: -80px;
    }
    
    .shape:last-child {
        right: -200px;
        bottom: 20px;
    }
    
    .forgotten-password-container {
        width: 70%;
        padding: 40px 30px;
    }
}


@media (max-width: 576px) {
    .background {
        width: 250px;
        height: 320px;
    }
    
    .shape:first-child {
        left: -150px;
        top: -50px;
    }
    
    .shape:last-child {
        right: -150px;
        bottom: 10px;
    }
    
    .forgotten-password-container {
        width: 90%;
        padding: 30px 20px;
    }
}

@media (max-width: 400px) {
    .background {
        width: 200px;
        height: 270px;
    }
    
    .shape:first-child {
        left: -100px;
        top: -40px;
    }
    
    .shape:last-child {
        right: -100px;
        bottom: 5px;
    }
    
    .forgotten-password-container {
        width: 100%;
        padding: 20px 15px;
    }
}

