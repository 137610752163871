.error-message {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.error-code {
    font-size: 120px;
    color: #007bff;
}

.error-text {
    font-size: 24px;
    color: #555;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    text-decoration: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}